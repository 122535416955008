import ReactMapboxGl, {
  GeoJSONLayer,
  Layer,
  Feature,
  Popup,
} from "react-mapbox-gl";
import React from "react";
import { observer, inject } from "mobx-react";
import MapPopup from "./MapPopup";
import MapSpatialFilter from "./MapSpatialFilter";
import UserInfo from "../UserInfo";
import MapSource from "./MapSource";
const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoicmVmdWVsIiwiYSI6ImNqZHFpYTM0MjA3bmcycXBkdXcxcXQ2YnUifQ.DAXfIcp0XwgnedyN1T2QpQ",
  customAttribution: "made with 💙 v1.6",
});

const heatmapPaint = {
  "heatmap-weight": {
    property: "priceIndicator",
    type: "exponential",
    stops: [
      [0, 0],
      [1, 10],
    ],
  },
  // Increase the heatmap color weight weight by zoom level
  // heatmap-ntensity is a multiplier on top of heatmap-weight
  "heatmap-intensity": {
    stops: [
      [0, 0.7],
      [22, 1.5],
    ],
  },
  // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
  // Begin color ramp at 0-stop with a 0-transparancy color
  // to create a blur-like effect.
  "heatmap-color": [
    "interpolate",
    ["linear"],
    ["heatmap-density"],
    0,
    "rgba(0,0,0, 0.1)",
    0.1,
    "rgba(0,0,255, 0.3)",
    0.4,
    "rgba(0,255,255, 0.6)",
    0.5,
    "rgba(255,255,150, 0.6)",
    0.7,
    "rgba(255,255,0, 0.6)",
    1,
    "rgba(255,150,0, 0.6)",
  ],
  // Adjust the heatmap radius by zoom level
  "heatmap-radius": {
    stops: [
      [0, 5],
      [20, 30],
    ],
  },
};

// const geojsonPaint = {
//   "fill-opacity": [
//     "interpolate",
//     ["linear"],
//     ["get", "percent"],
//     1,
//     0.05,
//     100,
//     0.4
//   ],
//   "fill-color": [
//     "step",
//     ["get", "percent"],
//     "gray",
//     1,
//     "green",
//     2,
//     "blue",
//     12,
//     "orange",
//     22,
//     "red",
//     100,
//     "purple"
//   ]
// };

// const colors = [
//   "#E65262",
//   "#F7C853",
//   "#9BCD65",
//   "#46C9A9",
//   "#F36B4F",
//   "#5A97E5",
//   "#A78DE6",
//   "#4CBBE1",
//   "#E583B9"
// ];

const circlePaint = (color) => ({
  "circle-color": color,
  "circle-opacity": 0.7,
  //"circle-stroke-width": 0.6,
  //"circle-stroke-color": "#ffffff",
  "circle-radius": {
    base: 1.5,
    stops: [
      [1, 1.5],
      [12, 3.5],
      [22, 60],
    ],
  },
});

class MapRenderer extends React.Component {
  state = {
    map: null,
  };

  onToggleHover(cursor, { map }) {
    if (!this.props.mapView.working) {
      map.getCanvas().style.cursor = cursor;
    }
  }

  onFeatureClick = (el) => {
    return (e) => {
      if (!this.props.mapView.working) {
        this.props.mapView.showPopup(el);
      }
    };
  };

  onMapLoad = (map) => {
    this.setState({ map });
  };

  render() {
    const { mapView, ui, store } = this.props;
    if (!mapView.loaded) return <p>Loading...</p>;

    return (
      <Map
        // eslint-disable-next-line
        //style="mapbox://styles/refuel/cjdyiqa5l8xxr2sjorz2bhq5p"
        style={mapView.mapStyle}
        containerStyle={{
          height: "100%",
          width: "100%",
        }}
        onStyleLoad={(map) => {
          this.onMapLoad(map);
        }}
        center={mapView.center}
        zoom={mapView.zoom}
        onDragStart={({ map, e }) => {
          mapView.popup = null;
        }}
        onDragEnd={(map, e) => {
          mapView.setCenter(map.getCenter());
        }}
        onZoomEnd={(map, e) => {
          mapView.setZoom(map.getZoom());
        }}
      >
        <Layer id="dummy" />
        {store.questionStore.geoFilters
          .filter((g) => g.type === "geo")
          .map((geoFilter) => {
            let key = `spatial-filters-${geoFilter.question.id}`;
            return (
              <GeoJSONLayer
                id={key}
                key={key}
                data={geoFilter.d}
                fillPaint={{
                  "fill-color": geoFilter.question.color,
                  "fill-outline-color": "#fff",
                  "fill-opacity": 0.3,
                }}
              />
            );
          })}

        {mapView.layers.map((layer, layerCount) => {
          switch (layer.activeType) {
            case "heatmap":
              return (
                <Layer
                  id={`heatmap-layer-${layerCount}`}
                  key={`heatmap-layer-${layerCount}`}
                  type="heatmap"
                  paint={heatmapPaint}
                  layout={{
                    visibility: layer.visible ? "visible" : "none",
                  }}
                >
                  {layer.data.map((el, index) => (
                    <Feature
                      key={index}
                      coordinates={el.value.geometry.coordinates}
                      properties={el.value.properties}
                    />
                  ))}
                </Layer>
              );
            case "markers":
              return (
                <Layer
                  id={`marker-layer-${layerCount}`}
                  key={`marker-layer-${layerCount}`}
                  type="circle"
                  paint={circlePaint(layer.geoQuestion.color)}
                  layout={{
                    visibility: layer.visible ? "visible" : "none",
                  }}
                >
                  {layer.data.map((el, index) => (
                    <Feature
                      key={index}
                      coordinates={el.value.geometry.coordinates}
                      properties={el.value.properties}
                      onClick={this.onFeatureClick(el)}
                      onMouseEnter={this.onToggleHover.bind(this, "pointer")}
                      onMouseLeave={this.onToggleHover.bind(this, "")}
                    />
                  ))}
                </Layer>
              );
            case "lines":
              //console.log(layer.data);
              return (
                <React.Fragment key={`line-layer-${layerCount}`}>
                  <GeoJSONLayer
                    linePaint={{
                      "line-color": "transparent",
                      "line-width": 6,
                      "line-opacity": 0,
                    }}
                    lineOnClick={(e) => {
                      //console.log("bla");
                      let f = e.features[0];
                      //console.log(f);
                      //console.log(layer.data);
                      let el = layer.data.find(
                        (o) => o.value.id + "" === f.id + ""
                      );
                      if (el) {
                        if (!this.props.mapView.working) {
                          this.props.mapView.showPopup(el, e.lngLat);
                        }
                      } else {
                        //console.log("Not el");
                      }
                    }}
                    data={{
                      type: "FeatureCollection",
                      features: layer.data.map((el) => el.value),
                    }}
                  />
                  <GeoJSONLayer
                    linePaint={{
                      "line-color": layer.geoQuestion.color,
                      "line-width": 1.5,
                      "line-opacity": 0.8,
                    }}
                    data={{
                      type: "FeatureCollection",
                      features: layer.data.map((el) => el.value),
                    }}
                  />
                </React.Fragment>
              );
            case "areas":
              return (
                <GeoJSONLayer
                  key={`area-layer-${layerCount}`}
                  fillPaint={{
                    "fill-color": layer.geoQuestion.color,
                    "fill-opacity": 0.5,
                  }}
                  fillOnClick={(e) => {
                    let f = e.features[0];
                    let el = layer.data.find(
                      (o) => o.value.id + "" === f.id + ""
                    );
                    if (el) {
                      if (!this.props.mapView.working) {
                        this.props.mapView.showPopup(el);
                      }
                    }
                  }}
                  data={{
                    type: "FeatureCollection",
                    features: layer.data.map((el) => el.value),
                  }}
                />
              );
            default:
              return null;
          }
        })}
        {mapView.hasSources &&
          mapView.sources.map((sc, n) => (
            <MapSource key={`source${n}`} source={sc} />
          ))}
        {mapView.popup !== null && (
          <Popup key="popup" coordinates={mapView.popup.coordinates}>
            <MapPopup
              popup={mapView.popup}
              onClose={() => (mapView.popup = null)}
              showUserInfo={() => {
                ui.modal = {
                  title: `User ${mapView.popup.userId}`,
                  children: <UserInfo userId={mapView.popup.userId} />,
                };
              }}
            />
          </Popup>
        )}

        {mapView.working && this.state.map !== null ? (
          <MapSpatialFilter map={this.state.map} mapView={mapView} />
        ) : (
          ""
        )}
      </Map>
    );
  }
}

export default inject("store", "ui")(observer(MapRenderer));

/*****
 * 
 * 
 * 
 * 
 * 

 {mapView.type === "poly" ? (
          <GeoJSONLayer
            data={mapView.geojson}
            fillOnClick={e => {
              let f = e.features[0];
              console.log(e);
              this.setState({
                showPopup: {
                  title: f.properties.PC5,
                  key: f.properties.PC5,
                  coordinates: [e.lngLat.lng, e.lngLat.lat],
                  content: `${f.properties.count} users`
                }
              });
            }}
            fillPaint={geojsonPaint}
          />
        ) : (
          ""
        )}
        {showPopup && (
          <Popup key={showPopup.key} coordinates={showPopup.coordinates}>
            <div>
              <h4>{showPopup.title}</h4>
              <p>{showPopup.content}</p>
            </div>
          </Popup>
        )}
 
<GeoJSONLayer
          data={chapter.mapView.data}
          fillOnClick={e => {
            
          }}
          fillPaint={{
            "fill-opacity": 0.5,
            "fill-color": [
              "step",
              ["get", "percent"],
              "yellow",
              20,
              "green",
              40,
              "blue",
              50,
              "orange",
              60,
              "red",
              70,
              "purple"
            ]
          }}
        />



        <Layer id="poly-layer" type="fill" paint={geojsonPaint}>
            {mapView.geojson.features.map((f, index) => {
              if (f.properties.percent === 25) {
                
              }
              return (
                <Feature
                  key={index}
                  coordinates={f.geometry.coordinates}
                  properties={f.properties}
                  onClick={e => {
                    
                  }}
                />
              );
            })}
          </Layer>
 */
