require("isomorphic-fetch");

export function fetchData(endpoint) {
  return fetch(endpoint).then((result) => {
    return result.json();
  });
}

export function formatData(v, format, id) {
  switch (format) {
    case "number":
      return parseInt(v.replace(/\D/g, ""), 10);
    case "date":
      return new Date(v.replace(/"/g, ""));
    case "json":
      //console.log(v);
      let o = JSON.parse(v);
      if (id) return { id, ...o };
      return o;
    case "string":
      return stripTags(v.replace(/"/g, ""));
    default: {
      return v;
    }
  }
}

function stripTags(s) {
  let result = s.replace(/<[^>]*>/g, "");
  return result.replace(/ +(?= )/g, "");
}
