import { formatData } from "../services/Api";
import { decorate, computed } from "mobx";

const IGNORE = ["buttonname", "createtime", "questionnaire", "respondent", "visibleLayers", "zoomLevel"];

class GeoAnswer {
  id;
  questionId = 0;
  question;
  value;
  userId = 0;

  files = [];

  constructor(data, question) {
    this.id = data.id + "";
    this.question = question;
    this.questionId = question.id + "";
    this.userId = data.respondent + "";
    this.value = formatData(data.json, question.format, this.id);

    this.question.addAnswer(this);
  }

  //SUPER HACK!
  get description() {
    let result = [];
    for (let key in this.value.properties) {
      if (!IGNORE.includes(key)) {
        let s = this.value.properties[key];
        if (s) {
          result.push(s);
        }
      }
    }
    return result;
  }

  get flatDescription() {
    let s = this.description;
    return s.join(" ").toLowerCase();
  }
}

export default decorate(GeoAnswer, {
  description: computed,
  flatDescription: computed
});
